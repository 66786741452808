import gql from 'graphql-tag';

export const ContentMenuFragment = gql`
	fragment ContentMenuFragment on Content {
		id
		type
		title
		space {
			id
			name
		}
		links {
			webui
			editui
		}
		metadata {
			currentuser {
				favourited {
					isFavourite
					favouritedDate
				}
			}
		}
		emoji: properties(keys: ["emoji-title-published", "emoji-title-draft"]) {
			nodes {
				id
				key
				value
			}
		}
	}
`;
