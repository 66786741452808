import gql from 'graphql-tag';

import { ContentMenuFragment } from './ContentMenuFragment.fragment';

export const StarredContentMenuQuery = gql`
	query StarredContentMenuQuery {
		favoriteContent(limit: 12) {
			nodes {
				...ContentMenuFragment
			}
		}
	}
	${ContentMenuFragment}
`;
